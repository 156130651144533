<template>
  <v-layout fill-height>
    <v-main>
      <PageBar
          :title="$tr('menu', 'key_17')"
          :drawer="true"
      />
      <Loader v-if="loading"/>
      <Container v-else>
        <Title :title="$tr('project', 'key_98')"/>
        <v-row class="mb-7">
          <v-col md="6" sm="12" cols="12">
            <v-text-field
                :label="$tr('project', 'key_93')"
                outlined
                color="primary"
                dense
                hide-details
                v-model="settings.api_key"
            ></v-text-field>
          </v-col>
          <v-col md="6" sm="12" cols="12">
            <v-text-field
                :label="$tr('project', 'key_94')"
                outlined
                color="primary"
                dense
                hide-details
                v-model="settings.app_id"
            ></v-text-field>
          </v-col>
          <v-col md="12" sm="12" cols="12">
            <v-text-field
                :label="$tr('project', 'key_140')"
                outlined
                color="primary"
                dense
                readonly
                hide-details
                :value="settings.sign_key"
            >
              <template v-slot:append>
                <v-btn icon color="danger" @click="reissue = true">
                  <RefreshMiniIcon :size="22"/>
                </v-btn>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <Title :title="$tr('project', 'key_97')"/>
        <div class="permissions_block mb-7">
          <div class="d-flex justify-space-between align-center pa-4">
            <div class="d-flex justify-start align-center">
              <v-avatar color="whitesmoke" :size="60" class="mr-4">
                <div class="android--text" style="width: 32px; height: 32px">
                  <AndroidLogoIcon :size="32"/>
                </div>
              </v-avatar>
              <div>
                <div class="body-1 font-weight-medium">
                  {{ $tr('project', 'key_95') }}
                </div>
                <div class="body-2 smoke--text">
                  {{ $tr('project', 'key_292') }}
                </div>
              </div>
            </div>
            <v-switch
                :true-value="1"
                :false-value="0"
                v-model="settings.android"
                inset
            ></v-switch>
          </div>
        </div>
        <Title title="Push message Membership"/>
        <template>
          <v-card
            :loading="loading"
            class="mx-auto my-12"
            min-width="500"
          >
            <v-card-text>
              <v-row
                align="center"
                class="mx-0"
              >
              </v-row>
              <template>
                <v-card
                  flat
                  color="transparent"
                >
                  <v-card-text>
                    <v-row>
                      <v-col class="pr-4">
                        <v-subheader :style="settings.plan === 0 ? 'color: red;' : 'color: blue;'" class="mb-7">{{ membershipexp }}</v-subheader>
                        <v-card-text class="pt-0">
                          <v-slider
                            v-model="slider"
                            thumb-color="red"
                            class="align-center"
                            :max="max"
                            :min="min"
                            thumb-label="always"
                            hide-details
                          >
                            <template v-slot:append>
                              <v-text-field
                                v-model="slider"
                                class="mt-0 pt-0"
                                hide-details
                                single-line
                                type="number"
                                @input="change"
                                style="width: 60px"
                              ></v-text-field>
                               <span style="display: flex;align-items: center;">/{{ (localplan*10000) }}</span>
                            </template>
                          </v-slider>

                        </v-card-text>
                        <template>
                          
                        </template>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </template>
            </v-card-text>
            <v-divider class="mx-4"></v-divider>
            <v-card-actions>
              <v-btn
                v-if="!switchPush && this.settings.plan !=0"
                depressed
                color="error"
                @click="removePush"
              >
                Cancel push membership
              </v-btn>
              <v-btn
                v-if="switchPush && (this.settings.plan !=0)"
                depressed
                color="primary"
                @click="back"
              >
                Back
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </Container>
      <Footer
          v-if="!loading"
          :loading="btnLoad"
          ref="footer"
          @click="updatePushDetail"
      />
      <v-dialog
          v-model="reissue"
          max-width="400"
      >
        <v-card class="pa-6">
          <div class="body-1 font-weight-bold danger--text mb-4">
            {{ $tr('project', 'key_142') }}
          </div>
          <div class="body-2">
            {{ $tr('project', 'key_143') }}
          </div>
          <div class="d-flex justify-end align-center mt-6">
            <v-btn
                text
                @click="reissue = false"
            >
              {{ $tr('project', 'key_145') }}
            </v-btn>
            <v-btn
                depressed
                color="danger"
                dark
                :loading="btn_load"
                class="ml-3"
                @click="reissueSign"
            >
              {{ $tr('project', 'key_144') }}
            </v-btn>
          </div>
        </v-card>
      </v-dialog>
    </v-main>
  </v-layout>
</template>

<script>
import PageBar from "@/components/blocks/PageBar";
import Container from "@/components/blocks/Container";
import Title from "@/components/blocks/Title";
import Loader from "@/components/blocks/Loader";
import AndroidLogoIcon from "@/components/icons/AndroidLogoIcon";
import Footer from "@/components/blocks/Footer";
import RefreshMiniIcon from "@/components/icons/RefreshMiniIcon";
export default {
  name: 'AdminPush',
  components: {
    PageBar,
    Container,
    Title,
    Loader,
    AndroidLogoIcon,
    Footer,
    RefreshMiniIcon
  },
  data: () => ({
    min: 0,
    max: 0,
    slider: 0,
    val: 0,
    localplan: 0,
    localpush: 0,
    switchPush:false,
    pushs:[
      {
        icon: 'mdi-plus-circle',
        title: '10,000',
        subtitle: 'Push notifications',
        money: 10,
      },
      {
        icon: 'mdi-plus-circle',
        title: '20,000',
        subtitle: 'Push notifications',
        money: 20,
      },
      {
        icon: 'mdi-plus-circle',
        title: '30,000',
        subtitle: 'Push notifications',
        money: 30,
      },
    ],
    loading: true,
    btnLoad: false,
    settings: {
      api_key: "",
      app_id: "",
      ios: 0,
      android: 0,
      sign_key: "",
      push: 0,
      plan: 0
    },
    reissue: false,
    btn_load: false
  }),  
  computed:{
    membershipexp(){
      console.log(this.settings.plan);
      if(this.settings.plan ==0 || this.switchPush == true){
        return "Membership subscription was canceled.";
      }
      else{
        return "Push membership $"+ (this.settings.plan*10) +"/month";
      }      
    }
  },
  methods: {
    change(){
      this.push = this.slider;
      this.max = this.slider;
    },
    removePush(){
      this.switchPush = true;
      this.localplan = 0;
    },
    back(){
      this.switchPush = false;
      this.localplan = this.settings.plan;
    },
    getPushSettings() {
      this.loading = true;
      this.$http.get(`${this.$serverApiLink}api/admin/push/detail/${this.$route.params.uid}`).
      then(
          response => {
            this.settings = response.data.detail;
            this.loading = false;
            this.localplan = this.settings.plan;
            this.localpush = this.settings.push;
            this.slider = this.localpush;
            if(this.settings.plan == 0){
              this.max = this.settings.push;
              this.membershipexp = "";
              this.switchPush = true;
            }else{
              this.max = this.localplan*10000;
            }           
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.loading = false;
          }
      );
    },
    reissueSign() {
      this.btn_load = true;
      this.$http.get(`${this.$serverApiLink}api/admin/push/reissue_sign/${this.$route.params.uid}`).
      then(
          response => {
            this.settings.sign_key = response.data.sign_key;
            this.reissue = false;
            this.btn_load = false;
            this.$refs.footer.showSuccessAlert();
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.btn_load = false;
          }
      );
    },
    updatePushDetail() {
      this.btnLoad = true;
      let params = new URLSearchParams();
      params.append('apple', this.settings.ios);
      params.append('android', this.settings.android);
      params.append('app_id', this.settings.app_id);
      params.append('api_key', this.settings.api_key);
      if(this.slider == 0){
        this.slider = this.push;
      }
      params.append('push', this.slider);
      params.append('plan', this.localplan);
      this.$http.post(`${this.$serverApiLink}api/admin/push/update/${this.$route.params.uid}`, params).
      then(
          // eslint-disable-next-line no-unused-vars
          response => {
            // console.log(response);
            this.btnLoad = false;
            this.$refs.footer.showSuccessAlert();
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.btnLoad = false;
          }
      );
    },
  },
  mounted() {
    this.getPushSettings();
  }
}
</script>