// export const config = {
//     "serverUrl": "http://localhost/backend/",
// };

// export const config = {
//     "serverUrl": "/backend/",
// };

export const config = {
    "serverUrl": "/",
};