<template>
  <div class="bar_padding">
    <div class="pa-3" style="padding-bottom: 17px!important;">
      <v-btn
          block
          depressed
          class="danger_btn"
          large
          @click="logout"
      >
        {{ $tr("menu", "key_5") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LogoutButton',
  components: {

  },
  data: () => ({

  }),
  methods: {
    logout() {      
      let params = new URLSearchParams();
      params.append('auth', '1665785604_850fa0ac8a758660ad43');
      this.$http.post(`${this.$serverApiLink}api/auth/logout`, params).
      then(
          response => {
            let result = response.data.message;
            if(result == "logout"){
              this.$store.dispatch('logout');
            }            
          }
      ).catch(
          error => {
            console.log(error);
          }
      );
      
    }
  },
}
</script>