<template>
    <div class="subscription-page">
      <div class="subscription-container">
        <h1>Flexible <span style="color:#888;"> Plans</span></h1>
        <p class="subtext" style="user-select: none;">
         Choose a plan that works for you
        </p>
  
        <!-- Plan Cards -->
        <div class="plan-cards">
          <div
            class="plan-card"
            v-for="(plan, index) in plans"
            :key="index"
            :class="{ 'selected-plan': selectedPlan === index }"
            @click="selectPlan(index)"
          >

          <p :class="{ 'selected-text-content': selectedPlan === index }" class="plan-comment">
                <span style="width:20px; height:20px; position: relative; top:4px;  margin-right:6px; ">
                    <svg style="width:18px; height:18px; position: relative;  " 
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#999" class="w-6 h-6">
                    <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-2.625 6c-.54 0-.828.419-.936.634a1.96 1.96 0 00-.189.866c0 .298.059.605.189.866.108.215.395.634.936.634.54 0 .828-.419.936-.634.13-.26.189-.568.189-.866 0-.298-.059-.605-.189-.866-.108-.215-.395-.634-.936-.634zm4.314.634c.108-.215.395-.634.936-.634.54 0 .828.419.936.634.13.26.189.568.189.866 0 .298-.059.605-.189.866-.108.215-.395.634-.936.634-.54 0-.828-.419-.936-.634a1.96 1.96 0 01-.189-.866c0-.298.059-.605.189-.866zm2.023 6.828a.75.75 0 10-1.06-1.06 3.75 3.75 0 01-5.304 0 .75.75 0 00-1.06 1.06 5.25 5.25 0 007.424 0z" clip-rule="evenodd" />
                    </svg>

                </span>


              <span class="feature-highlight-">{{ plan.comment }} </span>
          </p>

            <h2 :class="{ 'selected-text-content': selectedPlan === index }" class="plan-title" v-if="index===0">Basic</h2>
            <h2 :class="{ 'selected-text-content': selectedPlan === index }" class="plan-title" v-if="index===1">Startup</h2>
            <h2 :class="{ 'selected-text-content': selectedPlan === index }" class="plan-title" v-if="index===2">Enterprise</h2>
            <br/>

            <p :class="{ 'selected-text-content': selectedPlan === index }" class="plan-details">
                <span style="width:20px; height:20px; position: relative; top:4px;  margin-right:10px; ">
                <svg  style="width:20px; height:20px; " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                <path fill-rule="evenodd" d="M8.603 3.799A4.49 4.49 0 0112 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 013.498 1.307 4.491 4.491 0 011.307 3.497A4.49 4.49 0 0121.75 12a4.49 4.49 0 01-1.549 3.397 4.491 4.491 0 01-1.307 3.497 4.491 4.491 0 01-3.497 1.307A4.49 4.49 0 0112 21.75a4.49 4.49 0 01-3.397-1.549 4.49 4.49 0 01-3.498-1.306 4.491 4.491 0 01-1.307-3.498A4.49 4.49 0 012.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 011.307-3.497 4.49 4.49 0 013.497-1.307zm7.007 6.387a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clip-rule="evenodd" />
                </svg>
                </span>


              <span :class="{ 'selected-text-content': selectedPlan === index }"  class="feature-highlight">{{ plan.messages.toLocaleString() }} Push Messages</span> / Month
            </p>
            <p :class="{ 'selected-text-content': selectedPlan === index }" class="price">${{ plan.price }} <span class="price-per-month">/ month</span></p>
            <button @click="subscribe(plan)" class="subscribe-button">Subscribe</button>
          </div>

        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        
        plans: [
          { messages: 10000, price: 15, comment:'Individual' },
          { messages: 20000, price: 20, comment:'Small Team' },
          { messages: 30000, price: 25, comment:'Major Team' },
        ],
        selectedPlan: null, // Track the selected plan index
      };
    },
    methods: {
      selectPlan(index) {
        // Handle plan selection
        this.selectedPlan = index;
        this.$forcedUpdate()
      },
      subscribe(plan) {
        console.log(plan)
        // Implement subscription logic here
        alert(`Subscribed to Plan ${this.selectedPlan + 1}`);
      },
    },
  };
  </script>
  
  <style scoped>
  /* Custom CSS styles for the selectable subscription page */
  .subscription-page {
    padding: 40px 0;
    font-family: Arial, sans-serif;
    text-align: center;
  }
  
  .subscription-container {
    max-width: 900px;
    margin: 0 auto;
    padding: 30px;
    background-color: #fff;
    border-radius: 5px;
  
  }
  
  h1 {
    font-size: 32px;
    color: #333;
    margin-bottom: 20px;
    user-select: none;
  }
  
  .subtext {
    font-size: 15px;
    line-height: 1.6;
    margin-bottom: 60px;
    color:#888;
  }
  
  .plan-cards {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top:30px;
  }
  
  .plan-card {
    user-select: none;
    text-align: left;
    min-height:390px;
    width: 30%;
    padding: 30px;
    background-color: #fff;
    border: 1px solid #fcfcfc;
    border-radius: 12px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
  }
  
  .plan-card:hover {
    transform: scale(1.05);
  }
  
  .plan-card.selected-plan {
    background-color: #000;
    color: #fff;
  }
  
  .plan-title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  
  .selected-text-content{
    color:#ccc;

  }

  .plan-details {
    font-size: 16px;
   
    margin-bottom: 15px;
  }

  .plan-comment {
    font-size: 14px;
    margin-bottom: 15px;
  }
  
  .feature-highlight {
    font-size: 16px;
    font-weight: bold;
   
  }
  
  .price {
    font-size: 24px;
    font-weight: bold;
 
    margin-bottom: 10px;
  }
  
  .price-per-month {
    font-size: 16px;
    font-weight: normal;
  }
  
  .subscribe-button {
    background-color: #0496ff;
    color: #fff;
    border: none;
    padding: 12px 30px;
    border-radius: 12px;
    cursor: pointer;
    margin-top:40px;
    font-size: 14px;
    transition: background-color 0.3s ease-in-out;
  }
  
  .subscribe-button:hover {
    background-color: #0056b3;
  }
  </style>
  