<template>
  <div style=" ">

    <div class="grid-container">
      <div class="grid-item" style="">

        <div class="selectable-list">
          <div v-for="(item, index) in items" :key="index" :class="{ 'selected-list-item': selectedItem === index }"
            class="selectable-item" style="height:100px;">    
                    
            <span v-if="index === 0">
              <div class="form-container-">
                <div class="form-item-">
                  <div class="multi-col-form-item">
                    <div class="form-control w-full max-w-xs">
                      <label class="label">
                        <span class="label-text" style="margin-left:28px;" :for="'item' + index">{{ item.title }}</span>
                      </label>
                      <div class="join mat-10">
                        <input type="radio" style="border:1px solid #aaa;" :id="'item' + index" :name="'selection'"
                          v-model="selectedItem" :value="index" class="radio" :checked="selectedItem === index" />
                        <label style="font-size:13px; text-align: left; color:#666; font-weight: normal; ">Send push message to all active users</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </span>

            <span v-if="index === 1">              
              <div class="form-container-">
                <div class="form-item-">
                  <div class="multi-col-form-item">
                    <div class="form-control w-full max-w-xs">
                      <label class="label">
                        <span class="label-text" style="margin-left:28px;" :for="'item' + index">{{ item.title }}</span>
                      </label>
                      <div class="join mat-10">
                        <input type="radio" style="border:1px solid #aaa;" :id="'item' + index" :name="'selection'"
                          v-model="selectedItem" :value="index" class="radio" :checked="selectedItem === index" />
                        <label style="font-size:13px; text-align: left; color:#666; font-weight: normal; ">
                          Send push message to all active users in last 30 days</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </span>

            <span v-if="index === 2">
              <div class="form-container-">
                <div class="form-item-">
                  <div class="multi-col-form-item">
                    <div class="form-control w-full max-w-xs">
                      <label class="label">
                        <span class="label-text" style="margin-left:28px;" :for="'item' + index">{{ item.title }}</span>
                      </label>
                      <div class="join mat-10">
                        <input type="radio" style="border:1px solid #aaa; margin-top:-10px;" :id="'item' + index"
                          :name="'selection'" v-model="selectedItem" :value="index" class="radio"
                          :checked="selectedItem === index" />
                        <label style="font-size:13px; text-align: left; color:#666; font-weight: normal; ">
                          Send push message to all active users in last 60 days</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </span>
          </div>
        </div>

      </div>
      <div class="grid-item largest" style=" background:#fcfcfc; ">

        <div style="width:100%; margin-bottom:20px;">
          <label class="label">
            <span class="label-text" style="margin-left:0px;">Message title</span>
          </label>
          <div class="join mat-10" style="width:100%;">            
            <input
              style="width:90% !important; height:36px; background-color: white; font-size:12px; border:none; text-align:left; border-radius: 0px; box-shadow: 0 0 0 1px #f1f1f1; margin-left:4px; margin-right:4px;"
              type="text" v-model="notification.title" placeholder="24-Hour Flash Sale! 🛍️"
              value="24-Hour Flash Sale! 🛍️" class="input bg-w" />
          </div>
        </div>

        <div class="div500" style="margin-bottom:20px;">
          <label class="label">
            <span class="label-text" style="margin-left:0px;">For multimideia message</span>
          </label>
          <div class="join mat-10" style="width:100%;">
            <input
              style="width:90% !important; height:36px; background-color: white; font-size:12px; border:none; text-align:left; border-radius: 0px; box-shadow: 0 0 0 1px #f1f1f1; margin-left:4px; margin-right:4px;"
              type="text" v-model="notification.title" placeholder="write a image url to send" class="input bg-w" />
          </div>
        </div>

        <div style="width:100%;">
          <label class="label">
            <span class="label-text" style="margin-left:0px;">Message body</span>
          </label>
          <div class="join div500 mat-10">
            <textarea
              style="width:98%; height:150px; border:none; background-color: white; font-size:12px; text-align:left; border-radius: 0px; box-shadow: 0 0 0 1px #f1f1f1; "
              type="text" placeholder="" v-model="notification.text"
              class="input ">Grab the deals before they're gone. 🏃‍♂️💨</textarea>
          </div>
        </div>

        <div class="div500" style="text-align: left; margin-top:20px;">
          <button @click="sendPushNotification()" style=" height:36px; width:120px; border-radius: 4px; background:#003049; color:#fff; font-size:14px; "
            class="">

            <div class="join" style="">
              <label style="line-height: 36px;">
                Send</label>
            </div>

          </button>

        </div>
      </div>
    </div>

  </div>
</template>

<script>

// import DropZone from "@/components/form/DropZone";

export default {
  name: 'Apps',
  components: {
    // DropZone,
  },
  data: () => ({
    requestModel:'',
    modelPreview:'',
    selectedApp: 'App A',
    messageType: {
      selected: null,
      list: [
        'Text', 'Image'
      ],
    },
    recipients: '',
    message: '',
    imageFile: null,
    notification: {
      title: '🎉 Exciting Offer',
      text: `🎉 Exciting News Alert! 🚀 Don't miss out on our exclusive sale happening this weekend! 🔥 Get up to 50% off on your favorite products. 🛍️ Hurry, the clock is ticking! ⏰`,
      image: ''
    },
    currentTime: new Date().toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true }),
    selectedItem: null, // Holds the index of the selected item
    items: [      
      { title: "To All Users" },
      { title: "Active Last 30 Days" },
      { title: "Active Last 60 Days" },
    ],
    sort: 0,
    loading: false,
    list: [],
    offset: 0,
    count: 0,
    loadBtn: {
    status: true,
    loading: false
    },
  }),
  methods: { 

      selectMessageType(index) {
        this.messageType.selected = index;
      },

      updateFile(file) {      
        this.file = file;	     
      },

      updateTime() {
        this.currentTime = new Date().toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
      },

      sendPushNotification() {
        // this.$http.post(`${this.$serverApiLink}api/account/pushnoti/test`)
        let params = new URLSearchParams();
        params.append('title', this.notification.title );
        params.append('message', this.notification.text );

        this.$http.post(`${this.$serverApiLink}api/account/push/send/${this.$route.params.uid}`, params)
          .then(
              response => {
                console.log(response);
              }
          ).catch(
              error => {
                this.$store.commit('setSnackBar', {
                  code: !error.response ? 408 : error.response.status,
                  message: error.response.data.message
                });
              }
        );
      }
  },

  mounted() {
    // Update the time every second
    setInterval(this.updateTime, 1000);
    // Initial update
    this.updateTime();
  }
}
</script>


<style scoped>
.grid-container {
  display: grid;

  min-height: 500px;
  grid-template-columns: 1.0fr 2.0fr;
  /* Three columns with the middle one being twice as wide */


  gap: 20px;
  /* Adjust the gap as needed */
  /* Center text within grid items */
}

.grid-item {
  padding: 10px;

  background-color: #fefefe;
  /* Background color for the grid items */
}

.largest {
  grid-column: 2;
  /* Place the largest element in the center column */
}

.selectable-list {
  display: flex;
  flex-direction: column;
}

.selectable-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  border: 1px solid #f1f1f1;
  padding-right: 10px;
  padding-left: 10px;

  border-radius: 2px;
}

.selectable-list {
  display: flex;
  flex-direction: column;
}


.selected-list-item {

  box-shadow: 0 0 0 2px #666;
  background: #fbfbfb;

}


.selected-list-item:hover {

  box-shadow: 0 0 0 1px #f1f1f1;
  background: #f1f1f1;
  transition-delay: 400ms;
  transition: box-shadow 0.3s ease;
  transition: background-color 0.3s ease;

}

.logIcon{
  width: 200px;
  height: 200px;
}


input[type="radio"] {
  margin-right: 10px;
}

.label {
  flex-grow: 1;
}

.input-container {
  display: flex;
  flex-direction: column;
}

/* Optional styling for the inputs */
.input-container input {
  margin-bottom: 5px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.notification-container {

  border-radius: 16px;
  padding: 22px;
  width: 300px;
  /* Set your desired width */
  margin: 20px auto;
  font-family: Arial, sans-serif;
}

.notification-header,
.notification-footer {
  text-align: center;
}

.date {

  color: #404040;
}

.notification-card {
  display: flex;

  padding: 8px;

  border-radius: 16px;
  margin-top: 12px;
  min-height: 90px;
  background-color: rgba(255, 255, 255, 0.46);
}

.icon {
  flex: 0 0 40px;
  /* Adjust icon size as needed */
  margin-right: 12px;
  /* You can add styles for the icon, like background image or font-size here */
}

.content {
  flex-grow: 1;
}

.title {

  text-align: left;
  font-size: 12px;
  color: #000;
}

.description {

  line-height: 13px;
  text-align: left;
  font-size: 12px;
  color: #404040;
}

.time {
  font-weight: bold;
  font-size: 34px;
  color: #666;
}

.form-container {
  display: grid;
  grid-template-columns: 1fr 2fr;
  /* Two columns with the label narrower than the input */
  gap: 10px;
  /* Adjust the gap between label and input as needed */
  width: 300px;
  /* Set the container width as desired */
  margin: 20px auto;
}

.form-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 60px;
}

label {
  font-weight: bold;
}

input {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

/* You can add additional styling as needed */

.multi-col-form-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* Three columns */
  gap: 10px;
  /* Adjust the gap between label and input as needed */
  /* Set the container width as desired */
  margin: 20px auto;
}


.message-type {
  box-shadow: 0 0 0 1px #f1f1f1;
  background: #fff;
}

.message-type:hover {
  box-shadow: 0 0 0 1px #f1f1f1;
  background: #f1f1f1;
}

.selected-message-type {
  box-shadow: 0 0 0 2px #888;
  transition-delay: 400ms;
  transition: box-shadow 0.3s ease;
  background: #fbfbfb;

}

.selected-message-type:hover {
  box-shadow: 0 0 0 1px #f1f1f1;
  background: #f1f1f1;
  box-shadow: 0 0 0 2px #888;
}

.multi-col-form-item {
  display: flex;

  align-items: flex-start;
  /* Align items (labels) to the left */
}

label {
  font-weight: bold;
}

input {
  padding: 5px;

  border-radius: 3px;
}

.file-input {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  border-radius: 5px;
  text-align: center;

  height: 60px;
}
.div500{
  min-width: 300px;
}
/* @media (min-width: 600px) and (max-width: 900px) { */
  @media (max-width: 1200px) {
  /* Your CSS rules for this screen size range */
    .div500{
      margin-right: 312px;
    }
  }

  @media (min-width: 1200px) {
    /* Your CSS rules for this screen size range */
    .div500{
      margin-right: 0;
    }
  }

  input[type="file"] {}

  .file-name {
    font-size: 14px;
    color: #333;
    margin-top: 10px;
  }

  input[type="file"]+label::before {
    content: 'Browse';
    background-color: #007bff;
    color: #ffffff;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
  }

  input[type="file"]:focus+label::before {
    background-color: #0056b3;
  }

  input[type="file"]::-webkit-file-upload-button {
    display: none;
  }

  .pa-10{padding: 10px;}
  .pat-10{padding-top: 10px;}
  .pab-10{padding-bottom: 10px;}
  .pal-10{padding-left: 10px;}
  .par-10{padding-right: 10px;}
  .pa-20{padding: 20px;}
  .pat-20{padding-top: 20px;}
  .pab-20{padding-bottom: 20px;}
  .pal-20{padding-left: 20px;}
  .par-20{padding-right: 20px;}
  .ma-10{margin: 10px;}
  .mab-10{margin-bottom: 10px;}
  .mal-10{margin-left: 10px;}
  .mar-10{margin-right: 10px;}
  .mat-10{margin-top: 10px;}
  .ma-20{margin: 20px;}
  .mab-20{margin-bottom: 20px;}
  .mal-20{margin-left: 20px;}
  .mar-20{margin-right: 20px;}
  .mat-20{margin-top: 20px;}

/* You can add additional styling as needed */
</style>