<template>
  <div class="bar_padding">
    <PageBar :title="$tr('menu', 'key_40')" :preview="false"/>
    <v-list nav class="pt-0 pb-0">
      <div class="d-flex justify-space-between align-center">
        <v-subheader class="font-weight-medium smoke--text">
          {{ $tr('menu', 'key_30') }}
        </v-subheader>
        <v-tooltip
            bottom
            color="blue_dark"
            max-width="200"
        >
          <template v-slot:activator="{ on }">
            <v-icon size="16" style="top: 3px" color="primary" v-on="on">
              mdi-help-circle-outline
            </v-icon>
          </template>
          {{ $tr('project', 'key_359') }}
        </v-tooltip>
      </div>
      <div v-if="loading">
        <v-skeleton-loader
            v-for="n in 5"
            :key="'load_app_item_'+n"
            type="list-item-avatar-two-line"
            class="mb-3"
        />
      </div>
      <template v-else>
        <v-list-item v-if="!list.length">
          <v-list-item-content>
            <v-list-item-title class="black--text" v-text="$tr('project', 'key_282')"/>
          </v-list-item-content>
        </v-list-item>
        
        <v-list-item-group color="rgba(153, 162, 173, 1)">
          <template v-for="(item, index) in list">  
            <v-card
            style="margin-bottom: 10px;"
            v-model="selectApp"              
            elevation="17"
            :key="'notification_apps_'+index"
            @click="selectedApp(index)"
            >  
              <v-list-item :style="{ 'border': item.selected ? '1px solid blue' : 'none' }"  
              active-class="success--text text--accent-4"
              >              
                <v-list-item-avatar class="null_border_radius" size="34">
                  <AppIcon :size="34" :image="!item.icon ? null : item.icon"/>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="black--text" v-text="item.name"/>
                  <v-list-item-subtitle class="caption smoke--text" v-text="item.link"/>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </template>
        </v-list-item-group>
      </template>
    </v-list>
  </div>
</template>

<script>
import PageBar from "@/components/blocks/PageBar";
import AppIcon from "@/components/blocks/AppIcon";
export default {
  name: 'NotificationSidebar',
  components: {
    PageBar,
    AppIcon
  },
  data: () => ({
    selectApp: null,
    loading: true,
    list: []
  }),
  watch: {

  },
  methods: {
    selectedApp(index){
      let state = this.list[index]["selected"];
      this.list.forEach(item => {        
        item["selected"] = false;
        if(item["uid"] == this.$store.state.uid){
          item["membership"] = this.$store.state.push;
          item["active"] = this.$store.state.push_active;
          item["plan"] = this.$store.state.pushplan;
          item["expired_at"] = this.$store.state.push_exp;
          this.$store.dispatch('updateUid', null);
        }     
      });
      this.list[index]["selected"] = !state;
      if(!state == false){
        this.selectApp = null;      
        this.$store.dispatch('updatePushflag', null); 
        this.$store.dispatch('updateNotisel', false);
        this.$store.dispatch('updateApp', 
          {
            name: "",
            link: "",
            balance: 0,
            icon: null,
            uid: undefined,
            user_id: null
          }
        );
      }else{
        this.selectApp = index;
        this.$store.dispatch('updatePushflag', 'upgrade');  
        this.$store.dispatch('updateUid', this.list[this.selectApp]['uid']);
        this.$store.dispatch('updateNotisel', true);
        this.$store.dispatch('updatePush', this.list[this.selectApp]['membership']);
        this.$store.dispatch('updatePushplan', this.list[this.selectApp]['plan']);
        this.$store.dispatch('updatePushexp', this.list[this.selectApp]['expired_at']);
        this.$store.dispatch('updatePushactive', this.list[this.selectApp]['active']);
        this.$store.dispatch('updateApp', 
          {
            name: this.list[this.selectApp]['name'],
            link: this.list[this.selectApp]['link'],
            balance: this.list[this.selectApp]['balance'],
            icon: this.list[this.selectApp]['icon'],
            uid: this.list[this.selectApp]['uid'],
            user_id: this.list[this.selectApp]['user_id'],
          }
        );
      } 
  
    },
    getAppsList() {      
      this.$store.dispatch('updateUid', null);
      this.$store.dispatch('updatePush', 0);
      this.$store.dispatch('updatePushplan', 0);
      this.$store.dispatch('updatePushexp', null);
      this.$store.dispatch('updatePushactive', null);
      this.$store.dispatch('updateApp', 
          {
            name: "",
            link: "",
            balance: 0,
            icon: null,
            uid: undefined,
            user_id: null
          }
        );
      this.loading = true;
      this.$http.get(`${this.$serverApiLink}api/account/notification/apps`).
      then(
          response => {
            this.list = response.data.list;
            this.loading = false;
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.loading = false;
          }
      );
    }
  },
  mounted() {
    this.getAppsList();
  }
}
</script>