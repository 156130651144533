
<template>
  <v-layout fill-height>
    <v-main>
      <PageBar
          :title="`Push Notifications`"
          :preview="false"
          drawer
      ></PageBar>
     
      <template >
       
        <v-container fluid>
          <div class="app_grid">
         
          </div>

          <div style="width:800px; height:200px; ">
            <div style="height:700px; ">
       

            <subscribeWidget v-if="customerIsSubsribed"/>



            <div class="join-" style="width:100%; display:flex; justify-content: space-between; margin-bottom:20px;">

              <div class="join" 
              style="margin-bottom:20px; margin-left:10px; text-align:left; display:flex; align-items:left; ">
              
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0020.25 18V6A2.25 2.25 0 0018 3.75H6A2.25 2.25 0 003.75 6v12A2.25 2.25 0 006 20.25z" />
</svg>


              </span>

              <span 
              style="margin-left:10px; font-size: 16px; line-height:22px; font-weight:bold; " >Dashboard</span>          
           
            </div>

            <router-link to="/account/apps/:uid/push">

            <button style="width:160px; border-radius: 4px; background:#f1f1f1;" class="btn btn-sm">
             
              <div class="join">
                <svg
                style="width:20px; margin-top:4px; height:20px;" 
                xmlns="http://www.w3.org/2000/svg"  fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
            </svg>
            <label style="line-height: 28px; margin-left:4px;">
            Push Message
            </label>

              </div>
             
          
            </button>
            </router-link>


        </div>


            <!---
            <div v-if="!customerIsSubsribed"  style="width:900px; height:50px; margin-top:4px;  margin-bottom:14px;  ">
              <div class="tabs">
                <a @click="showMetrics()" :class="canShowMetrics?`tab tab-bordered tab-active`:`tab tab-bordered`">Usage Metrics</a> 
                <a @click="showSendNotice()" :class="canShowSendNotice?`tab tab-bordered tab-active`:`tab tab-bordered`">Send Notification</a> 
              </div>

            </div>

          -->

            <div v-if="canShowMetrics" style=" ">
              <metricWidget v-if="!customerIsSubsribed" />
            </div>
            <div v-if="canShowSendNotice" style=" ">
              <pushWidget v-if="!customerIsSubsribed"/>
            </div>


   </div>


           
          </div>
          
       
        </v-container>
      </template>
    </v-main>
  </v-layout>
</template>


  
  <script>


import  metricWidget from "@/views/account/notifications/metrics";
import  subscribeWidget from "@/views/account/notifications/subscribe";
import  pushWidget from "@/views/account/notifications/push";

import PageBar from "@/components/blocks/PageBar";

  export default {
    components: {
        metricWidget,
        subscribeWidget,
        pushWidget,
        PageBar,
   
      
    },
    data: () => ({
     
        customerIsSubsribed:false,
        canShowMetrics:true,
        canShowSendNotice:false,
        sort: 0,
        loading: false,
        list: [],
        offset: 0,
        count: 0,
        loadBtn: {
          status: true,
          loading: false
        },

    }),
    methods: {
      showSendNotice(){
      this.canShowSendNotice=true
      this.canShowMetrics=false;
     // this.$forcedUpdate()


    },

    showMetrics(){
      this.canShowMetrics=true;
      this.canShowSendNotice=false
    //  this.$forcedUpdate()

    },
      async requestNotificationPermission() {
        try {
          const permission = await Notification.requestPermission();
          if (permission === 'granted') {
            this.showNotification('Hello, World!', 'You have granted notification permission.');
          } else if (permission === 'denied') {
            console.log('Notification permission denied.');
          } else {
            console.log('Notification permission dismissed.');
          }
        } catch (error) {
          console.error('Error requesting notification permission:', error);
        }
      },
  
      showNotification(title, message) {
        if ('serviceWorker' in navigator && 'PushManager' in window) {
          navigator.serviceWorker.ready
            .then((registration) => {
              registration.showNotification(title, {
                body: message,
                icon: '/path/to/icon.png', // Replace with your notification icon
              });
            })
            .catch((error) => {
              console.error('Error showing notification:', error);
            });
        }
      },
    },
  };
  </script>
  
<style scoped>

.tabs .tabs-boxed .tab{
  color:#000 !important;

}

.tabs .tabs-bordered .tab{
  color:#000 !important;

}

.tabs .tabs-boxed.tab-active{
  background-color: #f1f1f1;
}

