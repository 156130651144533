<template>
  <v-layout fill-height>
    <v-main>
      <PageBar
          :title="$tr('menu', 'key_36')"
          :preview="false"
          drawer
      />
      <Loader v-if="loading"/>
      <template v-else>
        <template v-if="error">
          <EmptyState
              :title="$tr('project', 'key_288')"
              :subtitle="$tr('project', 'key_289')"
              :is-error="true"
          >
            <template v-slot:icon>
              <div style="width: 45px; height: 45px">
                <ErrorIcon :size="45"/>
              </div>
            </template>
          </EmptyState>
        </template>               
        <template v-else> 
            <v-row v-if="$store.state.notisel ==true && deposit == false && computeActive == false" style="left:25%; z-index: 999; position: absolute; top: 350px; display: flex; align-items:center; flex-direction: column;">
              <h1 class="mb-7" style="color: red; font-weight: bold;">YOUR PUSH SUBSCRIPTION HAS EXPIRED!</h1>
              <span class="mb-7" style="text-align: center;">Please renew the payment to get access to Push Notification Messages.</span>
              <v-btn
              x-small
              style="max-width: 100px"
              color="success"
              @click="activatePush"
              >RENEW / ACTIVE</v-btn>
            </v-row>
          <v-row style="padding-left:30px; padding-top: 10px;">
            <v-col lg="4" xs="12" sm="6">
              <v-row>               
                <v-col lg="6" xs="6" sm="12">
                  <label>Push Service:</label>
                  <span v-if="computeActive == true && $store.state.notisel == true" style="color: green; padding-left: 5px;">Active</span>
                  <span v-if="computeActive == false && $store.state.notisel ==true" style="color: red; padding-left: 5px;">Canceled</span>                  
                </v-col>
                <v-col lg="6" xs="6" sm="12">
                    <label>Selected Plan:</label>
                    <span style="color: green; padding-left: 5px;" v-if="$store.state.notisel ==true">{{ $store.state.pushplan }}</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col lg="4" xs="12" sm="6">
              <v-row>
                <v-col lg="6" xs="6" sm="12">
                  <label>Available Balance:</label>
                  <span style="color: green; padding-left: 5px;" v-if="$store.state.notisel ==true">{{ $store.state.push }}</span>
                </v-col>
                <v-col lg="6" xs="6" sm="12">
                  <label>Active Until:</label>
                  <span style="color: red; padding-left: 5px;" v-if="$store.state.notisel ==true">{{ $store.state.push_exp }}</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col justify="center" lg="4" xs="12" sm="6">
                <v-btn
                v-if="$store.state.push_active == 'active' && $store.state.notisel ==true"
                color="primary"
                x-small
                style="margin-right: 20px"
                @click="changePush"
                >
                  Change Plan
                </v-btn>
                <v-btn 
                    v-if="$store.state.push_active === 'active' && $store.state.notisel ==true"
                    x-small
                    depressed
                    :loading="cancelLoad"
                    color="error"
                    @click="cancelSubscription($store.state.uid)"
                >
                    Cancel Plan
                </v-btn>

                <v-btn 
                  v-if="$store.state.push_active != 'active' && $store.state.notisel ==true"
                  color="success"
                  x-small
                  @click="activatePush"
                  >
                    RENEW / ACTIVE
                </v-btn>
            </v-col>
          </v-row>         
          <v-row
            class="mb-6"
            justify="center"
            no-gutters
            :style="{ 'opacity': computeActive==false ? 0.1:1 }"
          >
            <v-col lg="4" sm="12" style="padding:10px;">
              <template>                
                  <v-list>
                    <v-list-item-group>
                        <template v-for="(item, i) in plans">
                          <v-card
                            :v-model="selectPush"
                            elevation="17"
                            :key="`to-${i}`"
                            style="margin-top: 10px; margin-left: 10px;"
                            @click="selectPushPlan(i)"
                          >
                          <v-list-item :style="{ 'border': item.selected ? '1px solid blue' : 'none' }"  
                            active-class="primary--text text--accent-4"
                          >
                              <v-list-item-icon>
                                <v-icon
                                  large
                                  color="teal darken-2"
                                >
                                  mdi-email
                                </v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title v-text="item.title"></v-list-item-title>
                                <v-list-item-subtitle> {{item.subtitle}} </v-list-item-subtitle> 
                              </v-list-item-content> 
                          </v-list-item>
                          </v-card>
                        </template>
                    </v-list-item-group>
                  </v-list>
              </template>
            </v-col>
            <v-col lg="8" sm="12">              
              <v-container
                class="pa-4 pt-6"
              >
                <v-text-field
                  v-model="pushtitle"
                  label="Message title"
                  placeholder="Please input title"
                  filled
                  dense
                ></v-text-field>
                <v-switch 
                  v-model="switchurl" 
                  style="color: blue;"
                  >
                    <template v-slot:label>
                      <v-list-item-title>If you want to send image...</v-list-item-title>
                      <v-progress-circular
                        :indeterminate="!switchurl"
                        :value="0"
                        color="blue"
                        size="24"
                        class="ml-2"
                      ></v-progress-circular>
                    </template>
                  </v-switch>
                <v-text-field
                  v-if="switchurl"
                  v-model="pushImage"
                  :rules="[rules.url]"
                  label="Image URL"
                  placeholder="https://wwvalue.com/ct/2017/03/free-mountain-images-design-photo-manipulation-18.jpg"
                  filled
                  dense
                ></v-text-field>                
                <v-textarea
                  v-model="pushmessage"
                  auto-grow
                  filled
                  color="deep-purple"
                  label="Message content"
                ></v-textarea>      
                <template>
                  <v-row
                      align="center"
                      justify="end"
                      style="padding:10px;"
                    >
                    <v-btn
                      v-if="computeActive==true"
                      rounded
                      :loading="sendLoad"
                      color="primary"
                      dark
                      @click="sendPush()"
                    >
                    <v-icon
                        dark
                        left
                      >
                        mdi-send
                        </v-icon>
                      Send
                    </v-btn>
                  </v-row>
                </template>
              </v-container>
            </v-col>
          </v-row>
        </template>        
      </template>
      <template v-if="success">
        <v-container
         fluid
         style="bottom:150px; position: absolute; "
        >  
        <v-row>
            <v-alert
              shaped
              outlined
              type="success"
            >
              Successfully Sent push message!
            </v-alert>
          </v-row>
          </v-container>
      </template>
      <template>
        <v-container
         fluid
         style="bottom:0; position: absolute; "
        >
          <v-row 
            justify="space-between"
            style="padding:10px;"
          >
            <v-col 
            jsutify="center"
                  style="display:flex;flex-direction: column;"
            md="6">
                
                  <span style="padding-left: 10px;">
                    Push balance
                  </span>
                  <v-card
                    style="padding:10px; border: none; max-width:200px; margin-top: 20px">
                    <v-rating 
                      :value="computedRate"
                      color="amber"
                      dense
                      half-increments
                      readonly
                      size="14"
                    ></v-rating>
                    <div class="grey--text ms-4">
                      {{ $store.state.push }}/{{ $store.state.pushplan }}
                    </div>
                  </v-card>
            </v-col>
            <v-col 
              v-if="$store.state.pushplan != 0"
              md="6" 
              style="display:flex; justify-content:center; align-items: center;"
            >
              
            </v-col>
          </v-row>
        </v-container>
       
      </template>
      <v-dialog
        fullscreen
        scrollable
        persistent
        v-model="deposit"
      >
        <PushModal :app="$store.state.app" @close="deposit = false"/>
      </v-dialog>
    </v-main>
  </v-layout>
  
</template>

<script>
import PageBar from "@/components/blocks/PageBar";
import Loader from "@/components/blocks/Loader";
import EmptyState from "@/components/blocks/EmptyState";
import ErrorIcon from "@/components/icons/ErrorIcon";
import PushModal from "@/components/modals/PushModal";


export default {
  name: 'Newsletter',
  components: {
    PageBar,
    Loader,
    EmptyState,
    ErrorIcon,
    PushModal,
  },
  data: () => ({   
    deposit: false,
    cancelLoad: false,
    sendLoad: false,
    selApp: false,
    success: false,
    switchurl: false,
    selectPush: null,
    pushtitle: null,
    pushmessage: null,
    pushImage: null,
    rules: {      
        url: v => {
          if (!v) {  return true; }
          const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
          return urlRegex.test(v) || 'Invalid URL type!';
        },        
        required: v => !!v || 'Please type message.',
      },
    plans:[
      {
        id: 1,
        title: 'To All Users',
        subtitle: 'Send push message to all active users.',
        selected: false
      },
      {
        id: 2,
        title: 'Active Last 30 Days',
        subtitle: 'Send push message to all active users in last 30d.',
        selected: false
      },
      {
        id: 3,
        title: 'Active Last 60 Days',
        subtitle: 'Send push message to all active users in last 60d.',
        selected: false
      }
    ],
    loading: true,
    list: [],
    offset: 0,
    count: 0,
    loadBtn: {
      status: true,
      loading: false
    },
    stat: {
      total: 0,
      active: 0
    },
    uid: null,
    create: {
      status: false,
      title: "",
      message: "",
      led: {
        status: false,
        value: '#2D9CDB'
      },
      image: null,
      loading: false
    },
    error: false
  }),
  watch: {
    '$route.query.uid': function() {
      this.getUid();
    },
  },
  methods: {
    activatePush(){
      this.$store.dispatch('updatePushflag', 'new');
      this.openDeposite();
    },
    changePush(){
      this.$store.dispatch('updatePushflag', 'change');
      console.log(this.$store.state.pushplan);
      console.log(this.$store.state.pushflag);
      this.openDeposite();
    },
    openDeposite(){
      this.deposit = true;
    },
    cancelSubscription(uid){
      this.cancelLoad = true;
      let params = new URLSearchParams();
      params.append('uid', uid );
      this.$http.post(`${this.$serverApiLink}api/account/deposit/cancelSubscription`, params)
      .then(
        response => {
          if(response.data.code == 200){
            this.$store.dispatch('updatePushplan', 0);
            this.$store.dispatch('updatePushexp', response.data.expired_at);
            this.$store.dispatch('updatePushactive', null);
            this.$store.dispatch('updateNotisel', true);
            this.loading = false;
            this.cancelLoad = false;
          }
        }
      )
      .catch(
              error => {
                this.$store.commit('setSnackBar', {
                  code: !error.response ? 408 : error.response.status,
                  message: error.response.data.message
                });
                this.pushmessage = null;
                this.pushtitle = null;
                this.pushImage = null;
                this.create.loading = false;
                this.cancelLoad = false;
              }
        );
    },
    selectPushPlan(index){
      this.selectPush = index;
      let state = this.plans[index]["selected"];
      this.plans.forEach(item => {
        item["selected"] = false;
      });
      this.plans[index]["selected"] = !state;
      if(this.plans[index]["selected"] == false){
        this.selectPush = null;
      }
    },
    sendPush() { 
      this.sendLoad = true;
      if(this.$store.state.push <= 0){
        this.sendLoad = false;
        this.$store.commit('setSnackBar', {
            code: 400,
            message: ["Push balance is not enough!"]
        });
        this.create.loading = false;
      }else if(this.$store.state.uid == null){
        this.sendLoad = false;
        this.$store.commit('setSnackBar', {
            code: 400,
            message: ["Please select the app to send push notification."]
          });
          this.create.loading = false;
      }else if(this.selectPush == null){
        this.sendLoad = false;
        this.$store.commit('setSnackBar', {
            code: 400,
            message: ["You did not select the target for push notification."]
          });
          this.create.loading = false;
      }else if(this.pushtitle == null || this.pushmessage == null){
        this.sendLoad = false;
        this.$store.commit('setSnackBar', {
            code: 400,
            message: ["Please input the content correctly."]
          });
          this.create.loading = false;
      }else{        
        let params = new URLSearchParams();
        params.append('title', this.pushtitle );
        params.append('message', this.pushmessage );
        params.append('image', this.pushImage);
        params.append('uid', this.$store.state.uid);
        params.append('plan', this.selectPush);
        this.$http.post(`${this.$serverApiLink}api/account/push/send`, params)
          .then(
              response => {
                if(response.data == "No app user!"){
                  this.$store.commit('setSnackBar', {
                    code: 400,
                    message: ["There are not users who are using this app!"]
                  });
                  this.pushmessage = null;
                  this.pushtitle = null;
                  this.pushImage = null;
                  this.switchurl = false;
                  this.create.loading = false;
                  this.sendLoad = false;
                }else if(response.data == "No image!"){
                  this.$store.commit('setSnackBar', {
                    code: 400,
                    message: ["Please type the valid image URL!"]
                  });
                  this.pushmessage = null;
                  this.pushtitle = null;
                  this.pushImage = null;
                  this.sendLoad = false;
                  this.create.loading = false;
                }else if(response.data == "successfully sent"){
                    this.pushmessage = null;
                    this.pushtitle = null;
                    this.pushImage = null;
                    this.sendLoad = false;
                    this.switchurl = false;
                    this.create.loading = true;
                    // this.$store.dispatch('updateUid', null);
                    this.$store.dispatch('updatePush', (this.$store.state.push-1));
                    this.displaySuccess();
                }else{
                  this.$store.commit('setSnackBar', {
                    code: 400,
                    message: [response.data]
                  });
                  this.pushmessage = null;
                  this.pushtitle = null;
                  this.pushImage = null;
                  this.sendLoad = false;
                  this.switchurl = false;
                  this.create.loading = false;
                }                
              }
          ).catch(
              error => {
                this.$store.commit('setSnackBar', {
                  code: !error.response ? 408 : error.response.status,
                  message: error.response.data.message
                });
                this.pushmessage = null;
                this.sendLoad = false;
                this.pushtitle = null;
                this.pushImage = null;
                this.create.loading = false;
              }
        );

      }
    },
    displaySuccess(){
      this.success = true;
      setTimeout(() => {
        this.success = false;
      }, 3000);
    },
    updateList() {
      this.count = 0;
      this.offset = 0;
      this.loadBtn = {
        status: true,
        loading: false
      };
      this.getTransaction();
    },
    updateFile(file) {
      this.create.image = file;
    },
    setLed(value) {
      this.create.led = {
        status: false,
        value: value
      }
    },
    getUid() {  
      this.$store.dispatch('updateNotisel', false);    
      let uid = this.$route.query.uid === undefined ? null : this.$route.query.uid;
      this.list = [];
      this.offset = 0;
      this.count = 0;
      if (!uid) {
        this.uid = null;
        this.loading = false;
      } else {
        this.uid = uid;
        this.getTransaction();
      }
    },
    getTransaction() {
      this.loading = true;
      this.$http.get(`${this.$serverApiLink}api/account/notification/list/${this.uid}/${this.offset}`).
      then(
          response => {
            this.list = response.data.list;
            this.offset += 20;
            this.count = response.data.count;
            this.stat = response.data.stat;
            this.loading = false;
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.list = [];
            this.uid = null;
            this.loading = false;
            this.error = true;
          }
      );
    },
    loadMore() {
      this.loadBtn.loading = true;
      this.$http.get(`${this.$serverApiLink}api/account/notification/list/${this.uid}/${this.offset}`).
      then(
          response => {
            if (response.data.list.length > 0) {
              for (let i = 0; i < response.data.list.length; i++) {
                this.list.push(response.data.list[i]);
              }
            } else {
              this.loadBtn.status = false;
            }
            this.offset += 20;
            this.loadBtn.loading = false;
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.loadBtn.loading = false;
          }
      );
    }
  },
  computed: {
    computeActive(){
      let pushExpDate = new Date(this.$store.state.push_exp);
      pushExpDate.setDate(pushExpDate.getDate() + 1);
      if (pushExpDate < new Date()) {
          return false;
      } else {
          return true;
      }

    },
    computeCancelMem(){
      if (new Date(this.$store.state.push_exp) <= new Date()) {
        return false;
      }else{
        return true;
      }
    },
    computedRate() {
      const pushValue = this.$store.state.push;
      const pushPlanValue = this.$store.state.pushplan;
      
      // Check if pushPlanValue is not zero to avoid division by zero
      if (pushPlanValue !== 0) {
        return pushValue / pushPlanValue*5;        
      } else {
        return 0; // or any default value you prefer
      }
    }
  },
  mounted() {
    this.getUid();
  }
}
</script>