<template>
  <v-layout fill-height>
    <v-main>
      <PageBar
          :title="$tr('menu', 'key_17')"
          :drawer="true"
      />
      <Loader v-if="loading"/>
      <Container v-else>
        <Title :title="$tr('project', 'key_97')"/>
        <div class="permissions_block">
          <div class="d-flex justify-space-between align-center pa-4">
            <div class="d-flex justify-start align-center">
              <v-avatar color="whitesmoke" :size="60" class="mr-4">
                <div class="android--text" style="width: 32px; height: 32px">
                  <v-icon
                    large
                    color="white darken-2"
                  >
                    mdi-send
                  </v-icon>
                </div>
              </v-avatar>
              <div>
                <div class="body-1 font-weight-medium">
                  Want to communate with your users?
                </div>
                <div class="body-2 smoke--text">
                  Turn on this option and select a push notificatin plan.
                </div>
              </div>
            </div>
            <v-switch
                v-model="switchPlan"
                inset
            ></v-switch>
          </div>
        </div>
      </Container>
      <template v-if="switchPlan == true">
        <v-row justify="center">
            <template v-for="(item, index) in pushcards">
                <v-card
                  :style="{ 'border': item.selected ==true ? '1px solid blue' : 'none' }" 
                  max-width="344"
                  :key="index"
                  style="margin: 10px"
                >
                  <v-list-item>
                    <v-list-item-content>
                      <div class="text-overline mb-4">
                        {{ item.balance }}
                      </div>              
                      <v-list-item-subtitle>Push Message</v-list-item-subtitle>
                      <v-list-item-title class="mb-1">
                        <strong class="text-h4">${{ item.amount }}</strong><span>/month</span>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-card-actions>
                    <v-btn
                      style="background-color: black;"
                      class="ma-2 white--text" 
                      rounded 
                      @click="selectApp(index)"            
                    >
                      Select This Option
                    </v-btn>
                  </v-card-actions>
                  <v-divider class="mx-4"></v-divider>
                  <v-card-actions>
                    <v-list-item-subtitle v-text="`Push Plan ${index+1}`"></v-list-item-subtitle>
                  </v-card-actions>
                </v-card>
            </template>
        </v-row>
      </template>
    </v-main>
  </v-layout>
  
</template>

<script>
import PageBar from "@/components/blocks/PageBar";
import Container from "@/components/blocks/Container";
import Title from "@/components/blocks/Title";
import Loader from "@/components/blocks/Loader";
export default {
  name: 'Push',
  components: {
    PageBar,
    Container,
    Title,
    Loader,
  },
  data: () => ({
    pushcards:[
      {balance: 10000, amount: 10, selected: false},
      {balance: 20000, amount: 20, selected: false},
      {balance: 30000, amount: 30, selected: false},
    ],
    switchPlan: false,
    deposit: false,
    loading: true,
    btnLoad: false,
    settings: [],
    reissue: false,
    btn_load: false
  }),
  methods: {
    selectApp(index){      
      this.loading = true;
      var status = this.pushcards[index]["selected"];
      this.pushcards.forEach(item => {
        item["selected"] = false;              
      });      
      this.pushcards[index]["selected"] = !status;
      if(!status == false){
        this.$store.dispatch('updatePush', 0);
        this.$store.dispatch('updatePushplan', 0);
        this.$store.dispatch('updateApp', 
          {
            name: "",
            link: "",
            balance: 0,
            icon: null,
            uid: undefined,
            user_id: null
          }
        );
        this.loading = false;
      }else{
        this.deposit = true;
        
        // this.$store.dispatch('updateNotisel', true);
        this.$store.dispatch('updatePushplan', this.pushcards[index]['balance']);
        console.log(this.$store.state.pushplan+":::");
        this.$store.dispatch('updateApp', 
          {
            name: this.settings['name'],
            link: this.settings['link'],
            balance: this.settings['balance'],
            icon: this.settings['icon'],
            uid: this.settings['uid'],
            user_id: this.settings['user_id'],
          }
        );
        this.loading = false;
      }
    },
    getPushSettings() { 
      if(this.$store.state.pushplan>0){
          this.switchPlan = true;
          this.pushcards.forEach(item => {
            if(item["balance"] == this.$store.state.pushplan){
                item["selected"] = true;  
                this.$store.dispatch('updateNotisel', true);  
            }else{
                item["selected"] = false;     
            }       
          });
        }else{
          this.pushcards.forEach(item => {
                item["selected"] = false;     
          });
        }
      this.loading = true;
      this.$http.get(`${this.$serverApiLink}api/account/push/detail/${this.$route.params.uid}`).
      then(
          response => {
            this.settings = response.data.detail;
            console.log("respon:"+response.data.detail["plan"]);            
            this.$store.dispatch('updatePushplan', response.data.detail["plan"]);
            if(response.data.detail["plan"]>0){
              this.switchPlan = true;
              this.pushcards.forEach(item => {
                if(item["balance"] == response.data.detail["plan"]){
                    item["selected"] = true;  
                    this.$store.dispatch('updateNotisel', true);  
                }else{
                    item["selected"] = false;     
                }
                         
              });
            }
            this.loading = false;
          }
      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.loading = false;
          }
      );
    },

  },
  mounted() {
    this.getPushSettings();
  }
}
</script>