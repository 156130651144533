<template>
  <div>
        <div class="stats stats-vertical lg:stats-horizontal shadow" style="border-radius: 0px; gap:10px; margin:10px;">
              
              <div class="stat" style="width:100%;">
                <div class="stat-title">Apps</div>
                <div class="stat-value">4</div>
                <div class="stat-desc">Total Apps installed</div>
              </div>
              <div class="stat">
                <div class="stat-title">iOs</div>
                <div class="stat-value">1</div>
                <div class="stat-desc">↗︎ 400 (22%)</div>
              </div>
              <div class="stat">
                <div class="stat-title">Android</div>
                <div class="stat-value">3</div>
                <div class="stat-desc">↗︎ 400 (22%)</div>
              </div>
              
              <div class="stat">
                <div class="stat-title">Monthly Cap</div>
                <div class="stat-value">10,000</div>
                <div class="stat-desc">Basic Plan</div>
                <div class="stat-actions">
                  <button class="btn btn-sm">Upgrade Plan</button>
                </div>
              </div>
              <div class="stat">
                <div class="stat-title">Sent Today</div>
                <div class="stat-value">4,200</div>
                <div class="stat-desc">↗︎ 400 (22%)</div>
              </div>
              <div class="stat">
                <div class="stat-title">Total this Month</div>
                <div class="stat-value">1,200</div>
                <div class="stat-desc">Notifications sent of 10,000</div>
                <div class="stat-desc">Resets on October 3 at 00:00 UTC</div>



              </div>
              
            </div>



            <br/>


            <!---- list of push notifications --->
            <div class="join-" style="width:100%; display:flex; justify-content: space-between; margin-top:20px;">

<div class="join" 
style="margin-bottom:20px; margin-left:10px; text-align:left; display:flex; align-items:left; ">

<span>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
</svg>




</span>

<span 
style="margin-left:10px; font-size: 16px; line-height:22px; font-weight:bold; " >Message History</span>          

</div>



</div>

           
            
            


<!----->

<div class="activity-feed" style="width:100%;  " >

<div v-for="(message, index) in pushMessages" :key="index"

style="width:100%; margin-bottom:4px;" >
  <div class="grid-item">

    <div class="column" style="flex-basis: 3%; ">
      <span  style="line-height:40px; color:#000; cursor:pointer; ">{{message.id}}</span>
    </div>
    <div class="divider"></div>

    <div class="column" style="flex-basis: 20%;">
      <div style="margin-left:2px; " class="dsui-card">
        <p style="font-weight:bold; font-size:13px; cursor:pointer; line-height:40px; " class="username-">
       {{  message.title}}, </p>
      </div>
    </div>

  
    <div class="divider"></div>
    <div class="column" style="flex-basis: 18%;"><p style="line-height:40px; font-size:13px;">
  {{message.date_sent}}
    </p></div>
    <div class="divider"></div>
    <div class="column" style="flex-basis: 4%;">
      <div style="margin-left:6px; margin-top:4px;" class="dsui-avatar">
        <div class="w-8  dsui-mask dsui-mask-squircle">
<!----
          <img v-if="order.product.mediaSource" :src="order.product.mediaSource.url" />
        -->
        </div>
      </div>
    </div>
    <div class="divider"></div>
    <div class="column" style="flex-basis: 32%;">
      <div v-if=" message.body" style="margin-left:2px; margin-top:0px;" class="dsui-avatar ">
       <div class="product-details" v-if=" message.body">
         <p class="product-name" style="line-height:40px; font-size:13px;">{{ message.body }}</p>

       </div>

      </div>
    </div>
   
    <div class="divider"></div>
    <div class="column" style="flex-basis: 5%;">
      <div class="product-details" >

        <p class="product-price" style="line-height:40px;">1 User</p>
      </div>
    </div>

  </div>
</div>


</div>


<!----->
            
            
            
            
            
            

          </div>

</template>

<script>

export default {
  name: 'Apps',
  components: {
  
  },
  data: () => ({
    pushMessages:[
    {
      "id": 1,
      "title": "New Message from Friend",
      "body": "Hey there! How's it going? 😃",
      "type": "text",
      "date_sent": "2023-09-11T09:30:00Z",
      "app": "Messaging App",
      "recipients": [
        {
          "user_id": 123,
          "username": "user123",
          "avatar": "https://example.com/avatar123.jpg"
        },
        {
          "user_id": 456,
          "username": "user456",
          "avatar": "https://example.com/avatar456.jpg"
        }
      ]
    },
    {
      "id": 2,
      "title": "New Image from Vacation",
      "body": "Check out this amazing beach view! 🏖️",
      "type": "image",
      "date_sent": "2023-09-10T15:45:00Z",
      "app": "Travel Photo App",
      "recipients": [
        {
          "user_id": 789,
          "username": "user789",
          "avatar": "https://example.com/avatar789.jpg"
        }
      ]
    },
    {
      "id": 3,
      "title": "Event Reminder",
      "body": "Don't forget the team meeting tomorrow at 3 PM.",
      "type": "text",
      "date_sent": "2023-09-09T17:00:00Z",
      "app": "Task Manager",
      "recipients": [
        {
          "user_id": 101,
          "username": "user101",
          "avatar": "https://example.com/avatar101.jpg"
        },
        {
          "user_id": 202,
          "username": "user202",
          "avatar": "https://example.com/avatar202.jpg"
        }
      ]
    }
  ],

    sort: 0,
    loading: false,
    list: [],
    offset: 0,
    count: 0,
    loadBtn: {
      status: true,
      loading: false
    },
  }),
  methods: {


 
  },
  mounted() {
   
  }
}
</script>


<style scoped>

/* Add your component-specific styles here */
.info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.total-messages,
.consumed,
.remaining {
  flex-basis: 30%;
  text-align: center;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.custom-chart {
  display: flex;
  justify-content: space-between;
  max-width: 800px;
  margin: 0 auto;
  padding-top: 20px;
}

.chart-bar {
  text-align: center;
  flex-basis: 30%;
  padding: 10px;
}

.bar-label {
  margin-bottom: 5px;
  font-weight: bold;
}

.bar {
  background-color: #007bff; /* Adjust bar color as needed */
  width: 30px;
  transition: height 0.3s;
}



.animated-div-view-product {
    line-height:40px;
    margin-top:16px;
    width: 40px;
    height: 10px;
    background-color: #f1f1f1;
    border-radius: 4px;
    animation: scaleAnimation 1s ease-in-out infinite alternate;
  }


  .animated-div-add-cart {
    line-height:40px;
    margin-top:16px;
    width: 40px;
    height: 10px;
    background-color: #666;
    border-radius: 4px;
    animation: scaleAnimation 1s ease-in-out infinite alternate;
  }

.animated-div-checkout {
  line-height:40px;
  margin-top:16px;
  width: 40px;
  height: 10px;
  background-color: #06d6a0;
  border-radius: 4px;
  animation: scaleAnimation 1s ease-in-out infinite alternate;
}


.animated-div {
  line-height:40px;
  margin-top:16px;
  width: 40px;
  height: 6px;
  background-color: #06d6a0;
  border-radius: 4px;
  animation: scaleAnimation 1s ease-in-out infinite alternate;
}

@keyframes scaleAnimation {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(0.4);
  }
  40% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1.1);
  }
}
  .grid-item {
    display: flex;
    align-items: center;
    border-bottom:1px solid #f1f1f1;
    padding-bottom:4px;
  }

  .grid-item:hover {
    display: flex;
    align-items: center;
    border-bottom:1px solid #f1f1f1;
    padding-bottom:4px;
    background:#fbfbfb;
  }

  .column {
    height: 40px;

    border-radius: 4px;
    margin-right: 10px;
  }

  .divider {
    padding:1.2px;
    width: 1px;
    border-radius:4px;
    height: 10px;
    background-color: #f1f1f1;
    margin-right: 10px;
  }

.activity-feed {
  max-width: 1240px;
  margin: 0 auto;
  padding: 0px;
}

.activity-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

.activity {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 4px;
}
.avatar img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
}

.action {
  text-align: center;
}

.username {
  font-weight: bold;
}

.product-photo {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 4px;
  margin-bottom: 10px;
}

.product-details {
  text-align: center;
}

.product-name {
  text-align:left;
}

.product-price {
    text-align:left;
  color: #000;
  font-size: 12px;
}

.timestamp {
  font-size: 12px;
  color: #999;
  margin-top: 4px;
}


.stat{
   margin:10px;
}
</style>