
<template>
  <v-layout fill-height>
    <v-main>
      <PageBar
          :title="`Push Notification  / New`"
          :preview="false"
          drawer
      ></PageBar>
     
      <template >
       
        <v-container fluid>
          <div class="app_grid">
         
          </div>

          <div v-if="canShowSendNotice" style="  ">
              <pushWidget v-if="!customerIsSubsribed"/>
            </div>
          
       
        </v-container>
      </template>
      
    </v-main>
  </v-layout>
</template>


  
  <script>


import  pushWidget from "@/views/account/notifications/push";

import PageBar from "@/components/blocks/BackNotice";

  export default {
    components: {

        pushWidget,
        PageBar,
   
      
    },
    data: () => ({
     
        customerIsSubsribed:false,
        canShowMetrics:false,
        canShowSendNotice:true,
        sort: 0,
        loading: false,
        list: [],
        offset: 0,
        count: 0,
        loadBtn: {
          status: true,
          loading: false
        },

    }),
    methods: {
      showSendNotice(){
      this.canShowSendNotice=true
      this.canShowMetrics=false;
     // this.$forcedUpdate()


    },

    showMetrics(){
      this.canShowMetrics=true;
      this.canShowSendNotice=false
    //  this.$forcedUpdate()

    },
      async requestNotificationPermission() {
        try {
          const permission = await Notification.requestPermission();
          if (permission === 'granted') {
            this.showNotification('Hello, World!', 'You have granted notification permission.');
          } else if (permission === 'denied') {
            console.log('Notification permission denied.');
          } else {
            console.log('Notification permission dismissed.');
          }
        } catch (error) {
          console.error('Error requesting notification permission:', error);
        }
      },
  
      showNotification(title, message) {
        if ('serviceWorker' in navigator && 'PushManager' in window) {
          navigator.serviceWorker.ready
            .then((registration) => {
              registration.showNotification(title, {
                body: message,
                icon: '/path/to/icon.png', // Replace with your notification icon
              });
            })
            .catch((error) => {
              console.error('Error showing notification:', error);
            });
        }
      },
    },
  };
  </script>
  
<style scoped>

.tabs .tabs-boxed .tab{
  color:#000 !important;

}

.tabs .tabs-bordered .tab{
  color:#000 !important;

}

.tabs .tabs-boxed.tab-active{
  background-color: #f1f1f1;
}

